<template>
    <div class="translate-panel">
        <div class="translate-header">
            <div class="translate-title" v-if="!notupdate">
                Translate in:
                <el-select :disabled="translatedContent.length > 0" v-model="selectedLanguage"
                    placeholder="Select Language" size="small">
                    <el-option v-for="lang in languages" :key="lang.code" :label="lang.fullname" :value="lang.name">
                    </el-option>
                </el-select>
                <el-button type="success" size="small" @click="startTranslate" :loading="isTranslating"
                    :disabled="translatedContent.length > 0">
                    {{ getButtonText }}
                </el-button>
            </div>
            <div class="translate-actions">
                <el-dropdown class="export" @command="handleCommand">
                    <span class="el-dropdown-link">
                        Copy as<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="copymarkdown" icon="el-icon-c-scale-to-original">Copy as
                            Markdown</el-dropdown-item>
                        <el-dropdown-item command="copytext" icon="el-icon-tickets">Copy as
                            TXT</el-dropdown-item>
                        <el-dropdown-item command="downloadaspdf" icon="el-icon-download">Download as
                            PDF</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>

        <div class="translate-content">
            <div class="guide-tips" v-if="!translatedContent && !isTranslating">
                <div class="tip-box tip-1">
                    <div class="tip-content">
                        <i class="el-icon-info"></i>
                        Select your target language and click "Get it Now" to start translation
                    </div>
                </div>
                <div class="tip-box tip-2">
                    <div class="tip-content">
                        <i class="el-icon-info"></i>
                        After translation, you can copy the content as Markdown or TXT format
                    </div>
                </div>
            </div>
            <div v-else-if="!translatedContent && isTranslating" class="loading-container">
                <el-progress type="circle" :percentage="translateProgress"></el-progress>
                <p class="loading-text">Translating your notes...</p>
            </div>
            <div class="translated-text" v-else v-html="translatedContent"></div>
        </div>


    </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
export default {
    name: 'TranslateToggle',
    data() {
        return {
            selectedLanguage: 'English',
            // 语言列表 code为全名
            languages: [
                { code: 'en', name: 'English', fullname: 'English' },
                { code: 'zh', name: 'Chinese', fullname: '简体中文' },
                { code: 'fr', name: 'French', fullname: 'French' },
                { code: 'ja', name: 'Japanese', fullname: 'Japanese' },
                { code: 'ko', name: 'Korean', fullname: 'Korean' }
            ],
            md: new MarkdownIt({
                html: true,
                linkify: true,
                typographer: true,
                highlight: function (str, lang) {
                    if (lang && hljs.getLanguage(lang)) {
                        try {
                            return hljs.highlight(lang, str).value;
                        } catch (__) {
                            console.log("error", str, lang);
                        }
                    }
                    return ""; // 使用默认的转义
                },
            }),

            note_translate: '',
            isTranslating: false,
            hasTranslated: false,
            translateProgress: 0,
            translateStatus: '',
            progressTimer: null
        }
    },
    props: {
        noteobj: {
            type: Object,
            default: null
        },
        notupdate: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        noteobj: {
            handler(newVal) {
                this.note_translate = newVal.note_translate
            },
            deep: true
        },
        'noteobj.noteid': {
            handler(newVal) {
                if (newVal) {
                    this.hasTranslated = false;
                    this.isTranslating = false;
                    this.note_translate = '';
                    this.clearProgressAnimation();
                }
            },
            immediate: true
        },
        selectedLanguage(newVal, oldVal) {
            if (newVal !== oldVal && oldVal) {
                this.hasTranslated = false;
                this.note_translate = '';
            }
        }
    },
    mounted() {
        this.note_translate = this.noteobj.note_translate;
        //打印当前路由
        console.log("current route", this.$route.path);
    },
    computed: {
        translatedContent() {
            if (this.note_translate) {
                return this.md.render(this.note_translate);
            } else {
                return ''
            }
        },
        getButtonText() {
            if (this.hasTranslated) return 'Translated'
            if (this.isTranslating) return 'Translating...'
            return 'Get it Now'
        }
    },
    methods: {
        async startTranslate() {
            if (!this.selectedLanguage) {
                this.$message.warning('Please select a language first')
                return
            }

            if (this.isTranslating || this.hasTranslated) {
                return
            }

            this.isTranslating = true;
            this.startProgressAnimation();

            try {
                const res = await this.$axios.post('/api/set_translate_note/', {
                    noteid: this.noteobj.noteid,
                    language: this.selectedLanguage,
                    note_text: this.noteobj.note_text,
                })
                const redata = res.data
                if (redata.code == 200) {
                    const noteid = redata.noteid
                    this.getTranslateResult(noteid)
                    this.$message.success("Translation started successfully");
                } else {
                    this.$message.error(redata.messages);
                    this.isTranslating = false;
                    this.clearProgressAnimation();
                }
            } catch (error) {
                this.$message.error("Failed to start translation");
                this.isTranslating = false;
                this.clearProgressAnimation();
            }
        },
        startProgressAnimation() {
            this.translateProgress = 0;
            let startTime = Date.now();
            const TOTAL_TIME = 120000; // 2分钟

            this.progressTimer = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                const progress = Math.min(99, Math.floor((elapsedTime / TOTAL_TIME) * 100));

                if (progress >= 99) {
                    clearInterval(this.progressTimer);
                }

                this.translateProgress = progress;
            }, 1000); // 每秒更新一次
        },
        clearProgressAnimation() {
            if (this.progressTimer) {
                clearInterval(this.progressTimer);
                this.progressTimer = null;
            }
            this.translateProgress = 0;
        },
        async getTranslateResult(noteid) {
            try {
                const res = await this.$axios.post('/api/get_translate_note/', {
                    noteid: noteid,
                });

                const redata = res.data;
                if (redata.code == 200) {
                    this.note_translate = redata.note_translate;
                    this.isTranslating = false;
                    this.hasTranslated = true;  // 标记已翻译
                    this.clearProgressAnimation();
                    this.$message.success("Translation completed successfully");
                } else if (redata.code == 202) {
                    // 继续轮询
                    setTimeout(() => this.getTranslateResult(noteid), 3000);
                } else {
                    throw new Error(redata.messages || 'Translation failed');
                }
            } catch (error) {
                this.isTranslating = false;
                this.clearProgressAnimation();
                this.$message.error(error.message || "Translation failed");
            }
        },
        copyToClipboard(text) {
            if (navigator.clipboard && window.isSecureContext) {
                navigator.clipboard.writeText(text).then(() => {
                    this.$message({ message: "Copied to clipboard", type: "success" });
                }).catch((err) => {
                    console.error('Could not copy text: ', err);
                });
            } else {
                // 使用旧的方法复制到剪贴板
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand('copy');
                    this.$message({ message: "Copied to clipboard", type: "success" });
                } catch (err) {
                    console.error('Could not copy text: ', err);
                }
                document.body.removeChild(textArea);
            }
        },
        handleCommand(command) {
            switch (command) {
                case "copymarkdown":
                    this.copymarkdown();
                    break;
                case "copytext":
                    this.copytext();
                    break;
                case "downloadaspdf":
                    this.downloadaspdf('translatecontent');
                    break;
                default:
                    break;
            }
        },
        copymarkdown() {
            console.log("copymarkdown", this.noteobj);
            const markdownContent = this.noteobj.note_text;
            this.copyToClipboard(markdownContent);
        },
        copytext() {
            const textContent = this.htmlToText(this.translatedContent);
            this.copyToClipboard(textContent);
        },
        downloadaspdf(resobj) {
            // this.generateA4PaginatedPdf();
            this.printPdf(resobj);
        },
        async printPdf(resobj) {
            try {
                // 获取打印容器
                const printContainer = this.$refs[resobj];
                if (!printContainer || !printContainer.innerHTML.trim()) {
                    throw new Error('No content to print');
                }

                // 创建一个隐藏的 iframe
                const iframe = document.createElement('iframe');
                iframe.style.position = 'absolute';
                iframe.style.top = '-9999px';
                iframe.style.left = '-9999px';
                document.body.appendChild(iframe);

                // 将打印容器的内容复制到 iframe 中
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframeDocument.open();
                iframeDocument.write(`
      <html>
        <head>
          <title>打印内容</title>
          <style>
            /* 添加你需要的样式 */
            body {
              font-family: Arial, sans-serif;
            }
            .print-container {
              padding: 20px;
            }
            ${this.getAdditionalStyles()} /* 获取额外需要的样式 */
          </style>
        </head>
        <body>
          ${printContainer.innerHTML}
        </body>
      </html>
    `);
                iframeDocument.close();

                // 等待 iframe 加载完成
                await new Promise((resolve) => {
                    iframe.onload = resolve;
                });

                // 等待一小段时间，确保所有内容都已渲染
                await new Promise((resolve) => setTimeout(resolve, 1000));

                // 调用浏览器的打印功能
                iframe.contentWindow.print();

                // 移除 iframe
                document.body.removeChild(iframe);
            } catch (error) {
                console.error('PDF打印失败:', error);
                alert('PDF打印失败，请稍后重试或联系支持人员。');
            }
        },
        // 获取额外需要的样式
        getAdditionalStyles() {
            // 这里可以返回任何额外需要的CSS
            return `
    /* 额外的CSS样式 */
    .custom-class {
      color: #333;
    }
  `;
        },
        htmlToText(html) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || '';
        },

    },
    beforeDestroy() {
        this.clearProgressAnimation();
    }
}
</script>

<style lang="less" scoped>
.translate-panel {
    flex: 1;
    max-height: calc(100vh - 130px);
    overflow: auto;
    color: #fff;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .translate-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .translate-title {
            display: flex;
            align-items: center;
            gap: 10px;

            :deep(.el-select) {
                width: 150px;
            }
        }

        .translate-actions {
            display: flex;
            align-items: center;
            gap: 10px;

            .export {
                position: absolute;
                right: 2.5vw;
                top: 0px;
                background: rgba(255, 255, 255, 0.2);
                color: #fff;
                border: none;
                font-size: 1vw;
                padding: 0.4vw 1vw;
                cursor: pointer;
                border-radius: 0.5vw;
                transition: all 0.3s ease;

                &:hover {
                    background: rgba(255, 255, 255, 0.4);
                }
            }
        }
    }

    .translate-content {
        flex: 1;
        overflow-y: auto;

        .guide-tips {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;
            padding: 0 20%;

            .tip-box {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 8px;
                padding: 20px;

                .tip-content {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    color: #bbb;
                    font-size: 16px;

                    i {
                        font-size: 24px;
                        color: #409EFF;
                    }
                }
            }
        }

        .translated-text {
            color: #fff;
            line-height: 1.6;

            h1 {
                margin: 0 !important;
            }

            table {
                // width: 100%;
                border-collapse: collapse;
                font-family: Arial, sans-serif;
                font-size: 16px;
                margin: 20px 0;
                text-align: left;

            }

            th,
            td {
                padding: 8px 12px;
                text-align: left;
                border: 1px solid #ddd;
            }

            th {
                background-color: #97929253;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1em;
                font-weight: 600;
            }

            td {
                font-size: 0.9em;
                line-height: 1.5;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 600;
            }

            h1 {
                margin: 0;
                font-size: 2em;
                border-bottom: 1px solid #eaecef;
                padding-bottom: 0.3em;
            }

            h2 {
                font-size: 1.5em;
                border-bottom: 1px solid #eaecef;
                padding-bottom: 0.3em;
            }

            h3 {
                font-size: 1.25em;
            }

            h4 {
                font-size: 1em;
            }

            h5 {
                font-size: 0.875em;
            }

            h6 {
                font-size: 0.85em;
                color: #6a737d;
            }

            p {
                margin-top: 0;
                margin-bottom: 16px;
            }

            a {
                color: #0366d6;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }

            code {
                padding: 0.2em 0.4em;
                margin: 0;
                font-size: 85%;
                background-color: rgba(27, 31, 35, 0.05);
                border-radius: 3px;
            }

            pre {
                padding: 12px;
                overflow: auto;
                line-height: 1.45;
                background-color: #111111;
                border-radius: 3px;
            }

            blockquote {
                padding: 0 1em;
                color: #c7ccd3;
                border-left: 0.25em solid #dfe2e5;
                margin: 0 0 16px 0;
            }

            ul,
            ol {
                padding-left: 2em;
                margin-top: 0;
                margin-bottom: 16px;
            }

            img {
                max-width: 100%;
                box-sizing: content-box;
            }
        }
    }
}

:deep(.el-button--text) {
    color: #fff;

    &:hover {
        color: #409EFF;
    }
}

:deep(.el-select .el-input__inner) {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 40px;

    .loading-text {
        margin-top: 20px;
        color: #fff;
        font-size: 16px;
    }
}

:deep(.el-progress) {
    .el-progress__text {
        color: #fff;
    }
}

:deep(.el-button.is-disabled) {
    background-color: #909399;
    border-color: #909399;

    &:hover {
        background-color: #909399;
        border-color: #909399;
    }
}
</style>
